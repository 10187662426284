import React from 'react'
import Link from 'gatsby-link'

const NotFoundPage = () => (
  <div className="grid-container">
    <h1>Whoops... whatever you came for, it's gone now :(</h1>
    <p>
      Sorry about that. Try the <Link to="/">home page</Link>
      , since it already lists practically everything.</p>
  </div>
)

export default NotFoundPage
